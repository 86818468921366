/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import/write your custom styles here.
/*  @ Styles from this file will override 'vendors.scss' and Fuse's base styles.
/* ----------------------------------------------------------------------------------------------------- */
@import './angular-overrides.scss';
@import './google-button.scss';
@import '~quill/dist/quill.snow.css';
@import './full-calendar.scss';
@import '@angular/cdk/overlay-prebuilt.css';

body {
  overflow: hidden;
}

* {
  &::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    cursor: pointer;
    background: rgba(0, 0, 0, 0.1);
  }

  &::-webkit-scrollbar-thumb {
    background: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}

.mat-mdc-dialog-content {
  max-height: 100vh !important;
  padding: 0 !important;
  overflow: hidden !important;
}

.mat-mdc-dialog-title {
  @apply px-0 pt-0 #{'!important'};
}

.mat-mdc-dialog-surface {
  @apply p-4 #{'!important'};
}

.mdc-dialog,
.mdc-dialog__scrim {
  height: auto !important;
}

.mdc-dialog__title::before {
  height: auto !important;
}

.mat-mdc-dialog-actions {
  padding: 0 !important;
  @apply pt-2 #{'!important'};
}

.field-label {
  @apply font-medium text-base #{'!important'};
}

.fieldos-full-screen-dialog {
  .mdc-dialog__container {
    width: 100%;
    height: 100%;
  }
  .mat-mdc-dialog-surface {
    @apply overflow-hidden rounded-none #{'!important'};
  }

  & .mat-mdc-dialog-content {
    overflow: hidden;
  }

  & .mat-mdc-dialog-container {
    padding: 0 !important;
  }
}

.cdk-overlay-container {
  z-index: 99999 !important;
}

.mdc-list-item__primary-text {
  width: 100% !important;
}

.fc-datagrid-cell-main {
  @apply block truncate #{'!important'};
}

.leader-line {
  z-index: 9999 !important;
}

.anchor {
  @apply text-md font-medium cursor-pointer font-bold;

  &:hover,
  &:focus {
    @apply no-underline opacity-75;
  }
}
