/* BUTTONS */
.mat-mdc-outlined-button.mat-success {
  @apply border-green-500 #{!important};
}

.mat-mdc-unelevated-button.mat-success {
  .mdc-button__ripple {
    @apply bg-green-500 #{!important};
  }
  @apply text-white #{!important};
}

.mat-mdc-outlined-button.mat-error {
  @apply border-red-500 #{!important};
}

.mat-mdc-unelevated-button.mat-error {
  .mdc-button__ripple {
    @apply bg-red-500 #{!important};
  }
  @apply text-white #{!important};
}

.mat-mdc-outlined-button.mat-warn .mdc-button__ripple {
  @apply border-orange-500 #{!important};
}

.mat-mdc-unelevated-button.mat-warn {
  .mdc-button__ripple {
    @apply bg-orange-500 #{!important};
  }
  @apply text-white #{!important};
}

.mat-mdc-outlined-button.mat-gray .mdc-button__ripple {
  @apply border-slate-500 #{!important};
}

.mat-mdc-unelevated-button.mat-gray {
  .mdc-button__ripple {
    @apply bg-slate-500 #{!important};
  }
  @apply text-white #{!important};
}

.mat-mdc-outlined-button.mat-light-gray .mdc-button__ripple {
  @apply border-slate-200 #{!important};
}

.mat-mdc-unelevated-button.mat-light-gray {
  .mdc-button__ripple {
    @apply bg-slate-200 #{!important};
  }

  @apply text-slate-700 #{!important};
}

/* CHIPS */
.mat-mdc-chip.mat-success {
  .mdc-evolution-chip__action--primary:before {
    @apply bg-green-500 #{!important};
    @apply border-green-500 #{!important};
  }

  & .mat-mdc-chip-action-label {
    @apply text-white #{!important};
  }
}

.mat-mdc-chip.mat-error {
  .mdc-evolution-chip__action--primary:before {
    @apply bg-red-500 #{!important};
    @apply border-red-500 #{!important};
  }

  & .mat-mdc-chip-action-label {
    @apply text-white #{!important};
  }
}

.mat-mdc-chip.mat-warn {
  .mdc-evolution-chip__action--primary:before {
    @apply bg-orange-500 #{!important};
    @apply border-orange-500 #{!important};
  }

  & .mat-mdc-chip-action-label {
    @apply text-white #{!important};
  }
}

.mat-mdc-chip.mat-gray {
  .mdc-evolution-chip__action--primary:before {
    @apply bg-slate-500 #{!important};
    @apply border-slate-500 #{!important};
  }

  & .mat-mdc-chip-action-label {
    @apply text-white #{!important};
  }
}

.mat-mdc-chip.mat-light-gray {
  .mdc-evolution-chip__action--primary:before {
    @apply bg-slate-200 #{!important};
    @apply border-slate-200 #{!important};
  }

  & .mat-mdc-chip-action-label {
    @apply text-slate-700 #{!important};
  }
}

.mat-mdc-card-header {
  @apply p-4 #{!important};
}

.mat-mdc-card-content {
  @apply p-4 #{!important};
}

.mat-mdc-icon-button.mat-success {
  .mat-icon {
    @apply text-green-500 #{!important};
  }
}
