.fc-theme-standard td,
.fc-theme-standard th {
    @apply border-opacity-10;
}

.fc-button:focus {
    @apply outline-none shadow-none #{'!important'};
}

@each $class in (resourceTimelineDay, resourceTimelineWeek, resourceTimelineMonth) {
    .fc-#{$class}-button {
        @apply bg-primary text-white rounded-full opacity-87 #{'!important'};

        &.fc-button-active {
            @apply px-4 opacity-100 #{'!important'};
        }
    }
}

.fc-today-button {
    @apply bg-primary text-white rounded-full px-4 #{'!important'};
    &:disabled {
        @apply bg-primary text-white rounded-full opacity-87 #{'!important'};
    }
}

.fc {
    .fc-toolbar.fc-header-toolbar {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @apply m-0;

        .fc-toolbar-title {
        }

        .fc-button.fc-button-primary {
            border: none;
            background-color: unset;
            @apply text-primary;

            &:hover {
                @apply text-opacity-80;
            }
        }
    }

    .fc-day-disabled {
        @apply bg-transparent;
    }

    .fc-daygrid {
        table {
            margin: 0;
            border-collapse: separate;
            border: none;

            a {
                text-decoration: none;
                @apply text-opacity-80;
            }

            .fc-daygrid-day-frame {
                display: flex;
                flex-direction: column;
                justify-content: center;

                .fc-daygrid-day-top {
                    font-size: 14px;
                    line-height: 140%;
                    font-weight: 500;
                }

                .fc-daygrid-day-events,
                .fc-daygrid-day-top {
                    display: flex;
                    flex-direction: row;
                    justify-content: center;
                    flex-wrap: wrap;
                    align-items: center;
                    gap: 0.1rem;
                    min-height: 0.5rem;

                    .fc-event-title {
                        display: none;
                    }

                    .fc-daygrid-day-bottom,
                    .fc-daygrid-day-bg {
                        display: none;
                    }

                    .fc-daygrid-event-harness {
                        display: flex;

                        .fc-daygrid-event {
                            margin: unset;
                            width: 10px;
                            height: 10px;
                            display: flex;
                            align-items: center;
                            justify-content: center;

                            .fc-event-time {
                                display: none;
                            }
                        }

                        .fc-daygrid-event-dot {
                            margin: unset;
                            border-width: 3px;
                        }
                    }
                }
            }

            .fc-day-today {
                background-color: transparent;
                border: 2px solid transparent;
                @apply border-primary border-opacity-100;
            }
        }
    }
}
